import React, { useCallback, useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Input from "../../../components/custom/Input";
import { useForm } from "react-hook-form";
import { createMultiPartFormBody } from "../../../util/Util";
import { includes, isEmpty } from "lodash";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Keyboard from "react-simple-keyboard";
import PinInput from "react-pin-input";
import "react-swipeable-list/dist/styles.css";
import { useSelector, useDispatch } from "react-redux";
import {
	stringIsNullOrEmpty,
	numberWithCurrencyFormat,
	createFormBody,
} from "../../../util/Util";
import {
	ApiKey,
	ApiUrl,
	WebUrl,
	TransactionType,
	BankingChannel,
	PanelType,
	AlertTypes,
} from "../../../util/Constant";
import ApiEngine from "../../../util/ApiEngine";
import {
	bindMember,
	setBusy,
	setBusyLoading,
	setCompleteInfoModal,
	setIdle,
	showMessage,
	showResponseMessage,
	setMyProfileModal,
	setDepositModal
} from "../../../redux/AppAction";
import "react-simple-keyboard/build/css/index.css";
import StyledSelect from "../../../components/custom/StyledSelect";
import moment from "moment";
import { heartBeat } from "../../../redux/AuthAction";
import { themeIcons } from "config/theme";
import VIcon from "components/custom/Icon";
import Accordion from "react-bootstrap/Accordion";
import LoadingOverlay from "react-loading-overlay";
import classNames from "classnames";
import { Spinner } from "react-bootstrap";
import CompleteInfoModal from "components/template_1/CompleteInfoModal";
import VerifyPhoneNumberModal from "components/template_1/VerifyPhoneNumberModal";
import AddPhoneModal from "components/template_1/AddPhoneModal";

const WithdrawByPhoneNumber = ({ onRenderComplete,selectedBankCode,onAfterHandleCheck }) => {
	var { t, i18n } = useTranslation();
	var _history = useHistory();
	let _location = useLocation();
	var _userData = useSelector((state) => state.authState.userData);
	const membershipEnabled = useSelector(
      (state) => state.appState.membershipEnabled
    );
	var [action, setAction] = useState([]);
	let _dispatch = useDispatch();
	const [addSuccessModelShow, setAddSuccessModelShow] = useState(false);
	const userFullName = useSelector(
		(state) => state.authState.userData.userFullName
	);
	const [selected, setSelected] = useState(false);
	const [selectedBank, setSelectedBank] = useState([]);
	const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null);
	const [amount, setAmount] = useState(0);
	const [value, setValue] = useState("");
	const [accountHolderName, setAccountHolderName] = useState("");
	const [hasPendingTxn, setHasPendingTxn] = useState(false);
	const [countdownStr, setCountdownStr] = useState("");
	const [timeDiffSeconds, setTimeDiffSeconds] = useState(0);
	const [countdownTime, setCountdownTime] = useState();
	const [isInsufficientAmount, setIsInsufficientAmount] = useState(false);
	const companyCurrency = useSelector((state) => state.appState.companyCurrency);
	const [showAddPhoneNumberModal, setShowAddPhoneNumberModal] = useState(false);
	const [bankError, setBankError] = useState(false);
	const [amountError, setAmountError] = useState(false);
	const [eWalletError, setEWalletError] = useState(false);
	const eWalletOTPEnabled = useSelector((state) => state.appState.eWalletOTPEnabled );

	const profileModal = useSelector(
		(state) => state.appState.profileModal
	);

	const {
		handleSubmit,
		register,
		errors,
		trigger,
		clearErrors,
	} = useForm();

	const [bankOption, setBankOption] = useState([]);
	const [phoneOption, setPhoneOption] = useState([]);
	const [transcactionNumber, setTranscactionNumber] = useState();
	const [isWithdrawalSuccess, setIsWithdrawalSuccess] = useState(false);
	const [withdrawalAmount, setWithdrawalAmount] = useState(0);
	const [withdrawalDate, setWithdrawalDate] = useState();
	// const [member, setMember] = useState();
	const member = useSelector((state) => state.appState.member);

	const _SECONDS_IN_MS = 1000;
	const _ALERT_TIMEOUT = 3000;
	const [countdownPeriod, setCountdownPeriod] = useState(0);
	const mainForm = useRef(null);

	const [success, setSuccess] = useState(false);
	const [successMsg, setSuccessMsg] = useState("");

	const [initDone, setInitDone] = useState(false);
	const [hasRolloverBal, setHasRolloverBal] = useState(false);

	const [showModal, setShowModal] = useState(false);
	const [min, setMin] = useState(0);
	const [max, setMax] = useState(0);
	const customerServiceUrl = "/livechat";
	const [totalWithdrawAmountToday, setTotalWithdrawAmountToday] = useState(0);
	const [totalWithdrawCountToday, setTotalWithdrawCountToday] = useState(0);
	const [isWithdrawalLimitError, setIsWithdrawalLimitError] = useState(0);
	const [remainingWithdrawalLimit, setRemainingWithdrawalLimit] = useState(0);
	const [memberWithdrawalAmountLimit, setMemberWithdrawalAmountLimit] = useState(0);
	const specificElementRef = useRef(null);

	const [isWithdrawLoading, setIsWithdrawLoading] = useState(false)
	const [selectedEwallet, setSelectedEwallet] = useState();
	const [showVerifyOtpModal, setShowVerifyOtpModal] = useState(false);
	const [phoneNumType, setPhoneNumType] = useState("");
	const [collapse, setCollapse] = useState(false);

	useEffect(() => {
		init();

	}, []);

	useEffect(() => {
		if (member) {
			if (!stringIsNullOrEmpty(member.userFullName)) {
				setAccountHolderName(member.userFullName);
			}
		} else {
			if (!stringIsNullOrEmpty(userFullName)) {
				setAccountHolderName(userFullName);
			}
		}
	}, [member, userFullName]);

	useEffect(() => {
		const checkElementRendered = () => {
			if (specificElementRef.current) {
				onRenderComplete();
			}
		};

		checkElementRendered();
	}, [onRenderComplete]);

	useEffect(() => {
		if (countdownPeriod > 0) {
			clearTimeout(countdownTime);
			setCountdownTime(
				setTimeout(() => {
					let tempPeriod = countdownPeriod - 1;
					setCountdownPeriod(tempPeriod);
					let tempX = moment.duration(tempPeriod);
					let tempMM = Math.floor(tempX["_milliseconds"] / 60);
					let tempSS = tempX["_milliseconds"] - tempMM * 60;
					setTimeDiffSeconds(tempX["_milliseconds"]);
					let tempStr =
						(tempMM.toString().length > 1 ? tempMM : "0" + tempMM) +
						" " +
						t("MINUTES") +
						" " +
						(tempSS.toString().length > 1 ? tempSS : "0" + tempSS) +
						" " +
						t("SECOND");
					setCountdownStr(tempStr);
				}, _SECONDS_IN_MS)
			);
		}
	}, [countdownPeriod]);

	useEffect(() => {
		// getMemberBankAccount();
		// getBankList();
	}, []);

	useEffect(() => {
		getMemberEwalletAccounts();
	}, [profileModal])

	useEffect(() => {
		if (bankOption && !stringIsNullOrEmpty(bankOption)) {
			let test = bankOption.find(x => x.bankCode == selectedBankCode)
			setSelectedBank(bankOption.find(x => x.bankCode == selectedBankCode))
			setSelectedEwallet(selectedBankCode)
		}
	}, [bankOption]);

	useEffect(() => {
		if (member && member.membership && member.membership.maxWithdrawalAmount && member.membership.maxWithdrawalCount) {
			// console.log(member.membership.maxWithdrawalAmount);
			// console.log(member.membership.maxWithdrawalCount);
			// console.log(totalWithdrawAmountToday);
			// console.log(totalWithdrawCountToday);
			setMemberWithdrawalAmountLimit(member.membership.maxWithdrawalAmount)
			////error 1 - Withdrawal limit amount is maxed out for today
			////error 2 - Withdrawal limit count is maxed out for today
			////error 3 - if value of input amount is greater than the withdraw amount limit remaining
			if (totalWithdrawAmountToday >= member.membership.maxWithdrawalAmount && member.membership.maxWithdrawalAmount > 0) {
				setIsWithdrawalLimitError(1);
			} else if (member.membership.maxWithdrawalCount > 0 && totalWithdrawCountToday >= member.membership.maxWithdrawalCount && member.membership.maxWithdrawalCount != -1) {
				setIsWithdrawalLimitError(2);
			} else {
				const withdrawAmountLimitRemainingToday = member.membership.maxWithdrawalAmount - totalWithdrawAmountToday;
				// console.log(withdrawAmountLimitRemainingToday)
				// console.log(amount)
				if (withdrawAmountLimitRemainingToday < amount && member.membership.maxWithdrawalAmount > 0) {
					setRemainingWithdrawalLimit(withdrawAmountLimitRemainingToday);
					setIsWithdrawalLimitError(3);
					console.log("the amount is greater than your daily limit to withdraw, here's your remaining total amount to withdraw for today - " + withdrawAmountLimitRemainingToday);
				} else {
					setIsWithdrawalLimitError(0);
				}
			}
		}
	}, [totalWithdrawCountToday, totalWithdrawAmountToday, member, amount]);


	async function init() {
		try {
			if (_userData["walletBalance"] == 0) {
				setIsInsufficientAmount(true);
			}

			getMemberDetails();
			
			var transactionTodayJson = await ApiEngine.get(
				ApiUrl._API_GET_TRANSACTION_TODAY_BY_USER
			);

			if (transactionTodayJson[ApiKey._API_SUCCESS_KEY]) {

				let transactionsToday = transactionTodayJson[ApiKey._API_DATA_KEY];
				if (transactionsToday) {
					const totalAmount = transactionsToday.reduce((accumulator, transactionsToday) => {
						return accumulator + transactionsToday.amount;
					}, 0);
					setTotalWithdrawAmountToday(totalAmount);
					setTotalWithdrawCountToday(transactionsToday.length);
				}

			} else {
				throw transactionTodayJson[ApiKey._API_MESSAGE_KEY];
			}

			getBankList();
			getMemberEwalletAccounts();

			// getMemberBankAccount();
			_dispatch(heartBeat());
			// } else {
			// 	setHasRolloverBal(true);
			// 	throw rolloverJson[ApiKey._API_MESSAGE_KEY];
			// }
		} catch (err) {
			_dispatch(showResponseMessage(false, err));
		}
		setInitDone(true);
	}

	async function getMemberEwalletAccounts() {
		let responseJson = await ApiEngine.get(
			// ApiUrl._API_GET_MEMBER_PHONE_NUMBERS + "?isVerifyRequired=true"
			ApiUrl._API_GET_MEMBER_PHONE_NUMBERS
		);
		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			let phoneNumberList = [];
			responseJson[ApiKey._API_DATA_KEY].map((phoneNumber) => {
				phoneNumberList.push({
					label: phoneNumber.phoneNumber,
					value: phoneNumber.id,
					id: phoneNumber.id, // CHRIS 20241206: added this here for convenience
					verified: phoneNumber.verified,
					phoneNumType: "memberEwalletAccounts", // member or memberEwalletAccounts
					accountHolderName: phoneNumber.accountHolderName
				});
			});
			setPhoneOption(phoneNumberList);
		} else {
			throw responseJson[ApiKey._API_MESSAGE_KEY];
		}
	}

	async function getMemberDetails() {
		await _dispatch(bindMember)
	}

	

	async function submitWithdrawal() {
		_dispatch(setBusyLoading(true))
		setIsWithdrawLoading(true)

		await getMemberDetails();

		if (isEmpty(member.phoneNumber)) {
			_dispatch(setCompleteInfoModal(true))
			_dispatch(setBusyLoading(false))
			setIsWithdrawLoading(false)

			return false
		}

		let createTransParams = {
			transactionTypeId: TransactionType._WITHDRAWAL,
			amount: amount,
			channel: "E-Wallet",
			panel: PanelType._MEMBERSITE,
			bankAccountNumber: selectedPhoneNumber.label,
			bankAccountHolderName: selectedPhoneNumber.accountHolderName,
			bankName: selectedBank.bankName,
			bankCode: selectedBank.bankCode,
		};


		let createTransResponseJson = await ApiEngine.post(
			ApiUrl._API_CREATE_TRANSACTION,
			createFormBody(createTransParams)
		);

		if (createTransResponseJson[ApiKey._API_SUCCESS_KEY]) {
			setWithdrawalAmount(amount);
			setIsWithdrawalSuccess(true);
			setWithdrawalDate(moment().format("DD MMM YYYY, hh:mm A"));
			setTranscactionNumber(createTransResponseJson[ApiKey._API_DATA_KEY]);
			_dispatch(heartBeat());
			init();
		} else {
			_dispatch(
				showResponseMessage(
					createTransResponseJson[ApiKey._API_SUCCESS_KEY],
					createTransResponseJson[ApiKey._API_MESSAGE_KEY]
				)
			);
		}

		// getMemberBankAccount();
		// _dispatch(setIdle());
		setIsWithdrawLoading(false)

		_dispatch(setBusyLoading(false))

	}

	async function onSubmit(data, e) {
		setBankError(false);
		setAmountError(false);
		setEWalletError(false);
		let error = false;
		if (stringIsNullOrEmpty(selectedEwallet)) {
			trigger("ewallet");
			setBankError(true);
			error = true;
		}

		if (
			!data.amount ||
			isNaN(data.amount) ||
			Number(data.amount) <= 0 ||
			Number(data.amount) < min
		) {
			trigger("amount");
			setAmountError(true);
			error = true;
		}

		if (stringIsNullOrEmpty(data.phoneNumber)) {
			trigger("phoneNumber");
			setEWalletError(true);
			error = true;
		} 

		if(!error)
		{
			submitWithdrawal();
		}

	}

	 const getBankList = async () => {
	 	let responseJson = await ApiEngine.get(
				ApiUrl._API_GET_USER_E_WALLET + "?type=WITHDRAWAL"
	 	);
	 	if (responseJson[ApiKey._API_SUCCESS_KEY]) {
	 		let bankList = [];
	 		responseJson[ApiKey._API_DATA_KEY].map((bankAccount) => {
	 			bankList.push({
	 				label: bankAccount.bankName,
	 				value: bankAccount.id,
	 				bankName: bankAccount.bankName,
	 				bankCode: bankAccount.bankCode,
	 				min: bankAccount.minimumWithdrawal,
	 				max: bankAccount.maximumWithdrawal,
					bankLogo: bankAccount.bankLogo
	 			});
	 		});
	 		setBankOption(bankList);
	 	} else {
	 		throw responseJson[ApiKey._API_MESSAGE_KEY];
	 	}
	 };

	function handleSelectedPhoneNumber(value) {
		const phoneNumberData = phoneOption.filter((x) => x.value == value)[0];

		setSelectedPhoneNumber(phoneNumberData);
		setValue(value);

	}

	const handleVerifyOtp = async (data, e) => {
		// Open modal
		setShowVerifyOtpModal(true);
	}

	useEffect(() => {
		if (phoneOption.length > 0 && selectedPhoneNumber) {
			const phoneNumberData = phoneOption.filter(
				(x) => x.value === selectedPhoneNumber.id
			)[0];

			setSelectedPhoneNumber(phoneNumberData);
			setValue(value);
		}
	}, [phoneOption]); // Runs whenever phoneOption updates after handleVerifyOtpCallback

	const handleAddPhoneCallback = async (response) => {
		setShowAddPhoneNumberModal(false);
		getMemberEwalletAccounts();
	};

	const handleVerifyOtpCallback = async (response) => {
		if (response[ApiKey._API_SUCCESS_KEY]) {
			// Close modal
			setShowVerifyOtpModal(false);

			// Refresh member phone numbers list
			await getMemberEwalletAccounts();
		}
	};
	const triggerParentFunction = () => {
        if (onAfterHandleCheck) {
            onAfterHandleCheck(); // Call the first parent function
        }
    };
	return (
		<>
			{success && (
				<div className="mt-3 mb-3 reminder-green">
					<div className="first">
						<div className="first">
							<img
								src={require("../../../assets/img/new_icons/green.png")}
								className="img-responsive"
								alt="icon"
							/>
						</div>
						<div>
							<p className="font14 mb-0 f-weight-500">{t(successMsg)}</p>
						</div>
					</div>
				</div>
			)}
			{
				// !hasPendingTxn && 
				!showModal && initDone && isWithdrawalLimitError != 1 && isWithdrawalLimitError != 2 && (
					<>
						<div className="t3-reload-bank-list-box d-block position-relative">
							<div className="reload-bank-details d-flex justify-content-between align-items-center">
								<span className="font14">{t("BALANCE")}</span>
								<span className="font14">
									{numberWithCurrencyFormat(
										_userData["walletBalance"],
										2,
										true
									)}
								</span>
							</div>
							<div className="reload-bank-details d-flex justify-content-between align-items-center">
								<span className="font14">{t("MIN_WITHDRAWAL")}</span>
								<span className="font14">
									{min ?
										numberWithCurrencyFormat(
											min,
											2,
											true
										)
										:
										"-"
									}
								</span>
							</div>
							
							{memberWithdrawalAmountLimit > 0 && (<div className="reload-bank-details d-flex justify-content-between align-items-start">
								<span className="font14">{t("MEMBERSHIP_DAILY_WITHDRAWAL_LIMIT")}<br/>
								{membershipEnabled && _userData.vipName != null &&
								  <div className="level">
									({t("MEMBERSHIP")}: {t(_userData.vipName.toUpperCase())})
								  </div>
								}
								</span>
								<span className="font14">
									{numberWithCurrencyFormat(
											memberWithdrawalAmountLimit,
											2,
											true
										)}
								</span>
							</div>)}

							<div className="border-top mt-2 mb-2"></div>
							<div className="reload-bank-details">
								<span className="yellow-note-title">{t("NOTES")} : </span>
								<p className="font14 mb-0">{t("WITHDRAWAL_NOTES_EWALLET") + "."}</p>
								{/* <p className="font14">
								{stringIsNullOrEmpty(memberBankAccounts)
									? t("BANK_ACC_WARNING") + "."
									: t("WITHDRAWAL_WARNING") + "."}
							</p> */}
							</div>
						</div>
						<form onSubmit={handleSubmit(onSubmit)} ref={mainForm}>

							<div className="mb-3 bank-selection">
							<label className="mb-1">{t("PLEASE_SELECT_EWALLET")}</label>
							<div className="bank-option-list mt-3" style={{ display: !collapse ? "flex" : "none" }}>
								{
									bankOption.map((bank, bankIndex)=>{
										return (
											<div key={bankIndex} className={`bank-option-box${bank.bankCode === selectedEwallet ? " selected": ""}`} 
												onClick={()=>{
													setBankError(false);
													setSelectedEwallet(bank.bankCode)
													setSelectedBank(bank)
													setMin(bank.min);
													setMax(bank.max);
												}}
											>
												<div className="bank-option-img">
												{
													bank.bankLogo ? (
														<img
															src={bank.bankLogo}
															className="img-responsive"
															alt="icon"
														/>
													) : ""
												}
												</div>
												<div className="bank-option-info">
													<div className="bank-option-name">{bank.bankName}</div>
													{ <div className="bank-option-minmax">{bank.min} - {bank.max}</div> }
												</div>
											</div>

										)
									})
								}

							</div>

							
							{bankError && (
								<div className="mt-2 mb-2 reminder-red full">
									<div className="first">
										<div className="first">
											<img
												src={require("../../../assets/img/new_icons/red.png")}
												className="img-responsive"
												alt="icon"
											/>
										</div>
										<div>
											<p className="text-left font14 mb-0 f-weight-500">
												<span dangerouslySetInnerHTML={{ __html: t("FIELD_REQUIRED") }} />
											</p>
										</div>
									</div>
								</div>
							)}


						</div>

							<div className="deposit-withdraw-form-label">{t("EWALLET_ACCOUNT_INFO")}</div>
							<div className="t3-deposit-box-wrapper">
								<div className="t3-withdraw-form-style-box">
									<div className="t3-input-withdrawal d-flex justify-content-between align-items-center">
										<label className="required col-3 mb-0 p-0">
											{t("PHONE_NUMBER")}
										</label>
										<div id="t3-custom-modal-settings" className="col-9 pr-0">
											<select
												className={
													// !hasRolloverBal &&
													_userData.walletBalance > numberWithCurrencyFormat(parseFloat(0), 2, true)
														// && stringIsNullOrEmpty(memberBankAccounts)
														? "t3-withdrawal-option-select"
														: "t3-withdrawal-option-select disabled"
												}
												name="phoneNumber"
												ref={register({
													required: "FIELD_REQUIRED",
												})}
												defaultValue={value}
												placeholder={t("PLEASE_SELECT_PHONE_NUMBER")}
												onChange={(e) => {
													setValue("phoneNumber", e.target.value)
													handleSelectedPhoneNumber(e.target.value);
													clearErrors("phoneNumber");
												}}
												required
											>
												<option value="" disabled>
													{t("PLEASE_SELECT_PHONE_NUMBER")}
												</option>
												{phoneOption.map((option, index) => {
													return (
														<option
															className="t3-withdrawal-option-value"
															value={option.value}
															key={index}
														>
															{option.accountHolderName} - {option.label}
														</option>
													);
												})}
											</select>
											{errors.phoneNumber && (
												<div className="d-flex align-items-center error-msg mt-1">
													<div className="font12 text-lgray3 second text-customred">
														{t(errors.phoneNumber.message)}
													</div>
												</div>
											)}
										</div>
									</div>
									{/* Show error when phone number is not yet verified */}
									{ eWalletOTPEnabled && selectedPhoneNumber && !selectedPhoneNumber.verified &&
										<div className="mt-2 mb-2 reminder-red full">
											<div className="first">
												<div className="first">
													<img
														src={require("../../../assets/img/new_icons/red.png")}
														className="img-responsive"
														alt="icon"
													/>
												</div>
												<div>
													<p className="text-left font14 mb-0 f-weight-500">
														<span dangerouslySetInnerHTML={{ __html: t('UNVERIFIED_PHONE_WARNING_MESSAGE') }} />
														<span
															className="ml-1 deposit-withdraw-verify-otp"
															onClick={() => {
																setPhoneNumType("member");
																handleVerifyOtp();
															}}
														>{t("VERIFY_OTP")}</span>
													</p>
												</div>
											</div>
										</div>
									}

									{phoneOption.length < 3 && (<div className="add-wallet-button-container mt-2">
                                        <span className="text-button" onClick={() => { setShowAddPhoneNumberModal(true); }}>{t("ADD_E_WALLET")}</span>
									</div>)}

									{_userData.walletBalance <= 0 && (
										<div className="mt-2 mb-2 reminder-red full">
											<div className="first">
												<div className="first">
													<img
														src={require("../../../assets/img/new_icons/red.png")}
														className="img-responsive"
														alt="icon"
													/>
												</div>
												<div>
													<p className="text-left font14 mb-0 f-weight-500">
														<span>{t('ACCOUNT_BALANCE_ZERO')}</span>
														{/* <span dangerouslySetInnerHTML={{ __html: t('FULLNAME_WARNING_MESSAGE', { url: customerServiceUrl }) }} /> */}
													</p>
												</div>
											</div>
										</div>
									)
									}

									{stringIsNullOrEmpty(_userData.userFullName) && _userData.walletBalance > 0 &&
										<div className="mt-2 mb-2 reminder-red full">
											<div className="first">
												<div className="first">
													<img
														src={require("../../../assets/img/new_icons/red.png")}
														className="img-responsive"
														alt="icon"
													/>
												</div>
												<div>
													<p className="text-left font14 mb-0 f-weight-500">
														<span dangerouslySetInnerHTML={{ __html: t('FULLNAME_WARNING_MESSAGE', { url: customerServiceUrl }) }} />
													</p>
												</div>
											</div>
										</div>
									}

									

									<div className="t3-input-withdrawal d-flex justify-content-between align-items-center mt-2">
										<label className="required col-3 mb-0 p-0">
											{t("AMOUNT")}
										</label>
										<div className="t3-withdraw-form-style-box position-relative col-9 pr-0">
											<div className="t3-wthdraw-input-style-box">
												<div className="first">{companyCurrency}</div>
												<div className="second">
													<input
														type="text"
														name="amount"
														ref={register({
															required: "FIELD_REQUIRED",
														})}
														// value={Number(amount).toFixed(2)}
														value={
															companyCurrency === "PKR"
																? Number(amount) > 0
																	? parseInt(amount) // Show without decimals for AUD
																	: 0
																: Number(amount) > 0
																? Number(amount).toFixed(2) // Show decimals for other currencies
																: 0
														}
														placeholder={
															t("ENTER_THE_AMOUNT") + " (" + companyCurrency + " " +
															numberWithCurrencyFormat(parseFloat(1), 2, true) +
															")"
														}
														className={
															_userData["walletBalance"] > 0
																? ""
																: "disabled-action"
														}
														onChange={(e) => {
															let value = 0;
															let amount = e.target.value
																.replace(
																	/[a-zA-Z`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?(kr|$|£|€)]*/g,
																	""
																)
																.trim();

															if (amount > 0) {
																if (companyCurrency === "PKR") {
																	value = Number(parseFloat(amount)).toFixed(0)
																} else {
																	value = Number(parseFloat(amount) / 100).toFixed(2);
																}
																setAmountError(false);
															}

															// CHRIS 20241210 - better not to do this, we show error to user to alert them, allow them to have agency to correct their amount
															// if (parseFloat(value) > parseFloat(_userData.walletBalance)) {
															// 	value = _userData.walletBalance;
															// }

															setAmount(value);

															// CHRIS 20241210 - better not to do this, allow user to have agency to manage their own cursor position
															// e.target.setSelectionRange(
															// 	value.length,
															// 	value.length
															// );
														}}
														disabled={
															!hasRolloverBal &&
																_userData.walletBalance >
																numberWithCurrencyFormat(parseFloat(0), 2, true)
																? ""
																: "disabled"
														}
													/>
												</div>
											</div>
										</div>
									</div>

									{amountError && (
										<div className="mt-2 mb-2 reminder-red full">
											<div className="first">
												<div className="first">
													<img
														src={require("../../../assets/img/new_icons/red.png")}
														className="img-responsive"
														alt="icon"
													/>
												</div>
												<div>
													<p className="text-left font14 mb-0 f-weight-500">
														{amount <= 0 && (<span dangerouslySetInnerHTML={{ __html: t("INVALID_AMOUNT_ERROR") }} />)}
														{(amount < min || amount > max) && (<span dangerouslySetInnerHTML={{ __html: t("MIN_MAX_RANGE",{
														0: (companyCurrency + " " + min),
														1: (companyCurrency + " " + max)}) }} />)}
													</p>
												</div>
											</div>
										</div>
									)}
									{isWithdrawalLimitError == 3 && (
										<div className="mt-2 mb-2 reminder-red full">
											<div className="first">
												<div className="first">
													<img
														src={require("../../../assets/img/new_icons/red.png")}
														className="img-responsive"
														alt="icon"
													/>
												</div>
												<div>
													<p className="text-left font14 mb-0 f-weight-500">
														<span dangerouslySetInnerHTML={{ __html: t("WITHDRAW_LIMIT_ERROR", {
														remainingWithdrawalLimit: remainingWithdrawalLimit,
														memberWithdrawalAmountLimit: memberWithdrawalAmountLimit,
													}) }} />
													</p>
												</div>
											</div>
										</div>
									)}


								</div>
							</div>

							<div ref={specificElementRef} className="t3-withdraw-buttons text-center mt-4 mb-2">
								<button
									type="submit"
									disabled={amount > 0.99 && isWithdrawalLimitError == 0 && (!eWalletOTPEnabled || selectedPhoneNumber?.verified) ? "" : "disabled" || hasRolloverBal || isWithdrawLoading}
									className={`
									t3-custom-light-btn 
									${amount > 0.99 && isWithdrawalLimitError == 0 && (!eWalletOTPEnabled || selectedPhoneNumber?.verified) ? "" : "disabled"}
								`}
								>
									{isWithdrawLoading ? (
										<Spinner
											className="dynamic-table-spinner"
											animation="border"
											role="status"
											size="sm"
											style={{
												verticalAlign: "middle",
												width: "1.5rem",
												height: "1.5rem",
												marginLeft: 3,
											}}
										></Spinner>
									) : (
										t("WITHDRAW")
									)}
								</button>
							</div>
						</form>
					</>
				)}
			{/* ////error 1 - Withdrawal limit amount is maxed out for today */}
			{!hasPendingTxn && !showModal && initDone && isWithdrawalLimitError == 1 && (
				<>
					<div ref={specificElementRef} class="mt-2 pr-1 text-danger text-center">{t("WITHDRAWAL_LIMIT_AMOUNT_MAX_ERROR")}</div>
				</>
			)}
			{/* ////error 2 - Withdrawal limit count is maxed out for today */}
			{!hasPendingTxn && !showModal && initDone && isWithdrawalLimitError == 2 && (
				<>
					<div ref={specificElementRef} class="mt-2 pr-1 text-danger text-center">{t("WITHDRAWAL_LIMIT_COUNT_MAX_ERROR")}</div>
				</>
			)}


			{/* WITHDRAW TRANSACTION SUCCESS START */}
			<Modal
				fade={false}
				id="t3-custom-modal-withdraw"
				isOpen={isWithdrawalSuccess}
				toggle={() => {
					setIsWithdrawalSuccess(false);
				}}
				backdrop={"static"}
				centered
			>
				<ModalBody>
					<div className="t3-custom-modal-withdraw-container t3-modal-bg-dark">
						<div className="t3-withdraw-modal-body t3-pin-modal-body d-flex align-items-center">
							<div
								className="t3-withdraw-success justify-content-center"
								id="Create-account"
							>
								<div className="text-center">
									<div className="">
										<img
											src={require("../../../assets/img/new_icons/checked.png")}
											className="img-responsive"
											alt="icon"
										/>
									</div>
									<h2 className="mt-3 text-white">
										{t("WITHDRAWAL_REQUEST_SUBMMITED")}
									</h2>
									<p className="font16 text-lgray2">
										{t("REQUEST_RECEIVED")}
									</p>
								</div>
								<div className="width-100-p mt-5">
									<a
										className="t3-custom-light-btn w-100 mt-4 d-flex align-items-center justify-content-center"
										onClick={() => {
											setIsWithdrawalSuccess(false);
											setSelected(false);
											triggerParentFunction();
										}}
										style={{ cursor: "pointer" }}
									>
										{t("GOT_IT")}
									</a>
								</div>
							</div>
						</div>
					</div>
				</ModalBody>
			</Modal>
			{/* WITHDRAW TRANSACTION SUCCESS END */}

			<AddPhoneModal
				show={showAddPhoneNumberModal}
				onClose={() => setShowAddPhoneNumberModal(false)}
				onCallback={(e) => {
					handleAddPhoneCallback(e)
				}}
			/> 

			{/* SUCESS MODAL START */}
			<Modal
				fade={false}
				id="t3-custom-modal-withdraw"
				isOpen={addSuccessModelShow}
				toggle={() => {
					setAddSuccessModelShow(false);
				}}
				centered
			>
				<ModalBody>
					<div className="t3-custom-modal-withdraw-container t3-modal-bg-dark">
						<div className="t3-withdraw-modal-body t3-pin-modal-body d-flex align-items-center">
							<div
								className="t3-withdraw-success justify-content-center w-100"
								id="Create-account"
							>
								<div className="text-center">
									<div className="">
										<img
											src={require("../../../assets/img/new_icons/checked.png")}
											className="img-responsive"
											alt="icon"
										/>
									</div>
									<h2 className="mt-3 text-white">{action} successful!</h2>
									<p className="font16 text-lgray2">{action} is completed!</p>
								</div>
								<div className="width-100-p mt-5">
									<a
										className="t3-custom-light-btn w-100 mt-4 d-flex align-items-center justify-content-center"
										onClick={() => {
											setAddSuccessModelShow(false);
											setSelected(false);
										}}
										style={{ cursor: "pointer" }}
									>
										{t("GOT_IT")}
									</a>
								</div>
							</div>
						</div>
					</div>
				</ModalBody>
			</Modal>
			{/* SUCCESS MODAL END */}
			
			<VerifyPhoneNumberModal
				show={showVerifyOtpModal}
				onClose={() => setShowVerifyOtpModal(false)}
				phoneNumber={selectedPhoneNumber?.label}
				phoneNumType={selectedPhoneNumber?.phoneNumType}
				onCallback={(e) => {
					handleVerifyOtpCallback(e)
				}}
			/>
		</>
	);
};

export default WithdrawByPhoneNumber;
