import { themeIcons } from "config/theme";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import VIcon from "components/custom/Icon";

import { useDispatch, useSelector } from "react-redux";
import { ApiKey, ApiUrl } from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine.js";
import { showResponseMessage, bindMember, setMyProfileModal, setCompleteInfoModal } from "../../redux/AppAction.js";
import { find, isEmpty } from "lodash";
import { useForm } from "react-hook-form";
import { createFormBody, stringIsNullOrEmpty } from "../../util/Util.js";
import { Spinner } from "react-bootstrap";

const AddPhoneModal = ({show, onClose, onCallback, ...props}) => {
    var _dispatch = useDispatch();
    var { t } = useTranslation();
    const PhoneNumberPrefix = useSelector((state) => state.appState.dialingCodes);
    const [selectedPhonePrefix, setSelectedPhonePrefix] = useState("");
    const [formattedPhoneNumber, setFormattedPhoneNumber] = useState(false);
    
    const {
      register: ewalletRegister,
      handleSubmit: ewalletHandleSubmit,
      errors: ewalletErrors,
      setError: ewalletError,
    } = useForm();

    function sanitizePhoneNumber(e) {
        const re = /^[0-9\b]+$/;
        let value = e.target.value;

        // Check if the value contains only digits
        if (re.test(value)) {
            // Limit the value to 12 characters
            if (value.length > 12) {
            value = value.slice(0, 12);
            }

            // setIsDigit(true);
            setFormattedPhoneNumber(value);
        } else {
            return false;
            // setIsDigit(false);
        }
    }

    // Add ewallet functions
    const addEwallet = async (data, e) => {
        let params = {
            accountHolderName: data.accountHolderName,
            phoneNumber: (selectedPhonePrefix == "" ? PhoneNumberPrefix[0].value : selectedPhonePrefix) + formattedPhoneNumber
        };

        let responseJson = await ApiEngine.post(
            ApiUrl._API_ADD_MEMBER_PHONE_NUMBER,
            createFormBody(params)
        );

        if (typeof responseJson[ApiKey._API_SUCCESS_KEY] !== "undefined") {
                bindMember(_dispatch);
                _dispatch(
                    showResponseMessage(
                        responseJson[ApiKey._API_SUCCESS_KEY],
                        responseJson[ApiKey._API_MESSAGE_KEY]
                    )
                );

                if(responseJson[ApiKey._API_SUCCESS_KEY]) {
                    if(onCallback) {
                        onCallback(responseJson);
                    }
                }
        }
    };


    const closeBtn = (
		<div
			className="close"
			onClick={() => _dispatch(setCompleteInfoModal(false))}
		>
			<VIcon icon={themeIcons.close} className="vicon" />
		</div>
	  );
    return (
      <div>
          <Modal
            className="modal-custom-fullscreen"
            contentClassName={"t3-custom-modal t3-custom-modal-with-back-btn"}
            fade={false}
            isOpen={show}
            toggle={() => {
              if (onClose) {
                onClose();
              }
            }}
          >
            <ModalHeader>
              <div className="first">
                <div>{t("ADD_PHONE_NUMBER")}</div>
              </div>
              <div className="second">
                <div className="t3-close-custom-modal" onClick={() => {
                    if (onClose) {
                      onClose();
                    }
                }}>
                  <VIcon icon={themeIcons.close} className="vicon" />
                </div>
              </div>
            </ModalHeader>
            <ModalBody>
              <div className="t3-custom-modal-container">
                <div className="modal-body">
                  <div>
                    <div className="d-flex flex-column align-items-center justify-content-center user-available p-20">
                      <form
                        className="w-100"
                        onSubmit={ewalletHandleSubmit(addEwallet)}
                      >
                        {/* <div className="t3-pin-title2 mb-3">
                          {t("ADD_NEW_SECONDARY_PHONE_NUMBER")}
                        </div> */}
                        <div className="t3-input-container">
                          <div className="t3-input-container">
                            <label>{t("ACCOUNT_HOLDER_NAME")}</label>
                          </div>
                          <div className="t3-contact-number-box">
                            <div className="second">
                              <input
                                name="accountHolderName"
                                className="t3-custom-input-text"
                                ref={ewalletRegister({
                                  required: true,
                                })}
                                errors={ewalletErrors}
                                onChange={(e) => sanitizePhoneNumber(e)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="t3-input-container mt-3">
                          <div className="t3-input-container">
                            <label>{t("MOBILE_NUMBER")}</label>
                          </div>
                          <div className="t3-contact-number-box">
                            <div className="first">
                              <select
                                onChange={(e) => {
                                  setSelectedPhonePrefix(e.target.value);
                                }}
                              >
                                {PhoneNumberPrefix.map((prefix, index) => {
                                  return (
                                    <option
                                      key={index}
                                      className={"phone-prefix-" + (index + 1)}
                                      value={prefix.value}
                                    >
                                      {prefix.label}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div className="second">
                              <input
                                name="registerPhone"
                                className="t3-custom-input-text"
                                autoComplete="new-password"
                                ref={ewalletRegister({
                                  required: true,
                                  minLength: {
                                    value: 8,
                                    message: t("MIN_CHARACTERS", { length: 8 }),
                                  },
                                  maxLength: {
                                    value: 11,
                                    message: t("MAX_CHARACTERS", { length: 11 }),
                                  },
                                  pattern: {
                                    value: /^[0-9]+$/g,
                                    message:
                                      t("MIN_CHARACTERS", { length: 8 }) +
                                      " " +
                                      t("AND") +
                                      " " +
                                      t("ONLY_NUMBERS"),
                                  },
                                })}
                                errors={ewalletErrors}
                                onKeyDown={(e) => {
                                  const key = e.key;
                                  const allowedKeys = [
                                    "Backspace",
                                    "ArrowLeft",
                                    "ArrowRight",
                                    "Delete",
                                    "Tab",
                                  ];

                                  if (
                                    !/[0-9]/.test(key) &&
                                    !allowedKeys.includes(key)
                                  ) {
                                    e.preventDefault();
                                  }

                                  // Prevent '0' as the first character
                                  // const inputValue = e.target.value;
                                  // if (inputValue.length === 0 && key === "0") {
                                  //   e.preventDefault();
                                  // }
                                }}
                                // onChange={(e) => sanitizePhoneNumber(e)}
                                onChange={(e) => {
                                  let newValue = e.target.value;
  
                                  // Remove all leading zeros
                                  // newValue = newValue.replace(/^0+/, '');
                          
                                  // Call your original `onChange` function
                                  sanitizePhoneNumber(e);
                          
                                  // Update the input field with the modified value
                                  e.target.value = newValue;
                                }}
                              />

                              {ewalletError == true ? (
                                <div className="d-flex align-items-center user-available">
                                  <div className="font12 text-lgray3 second text-customred">
                                    {t("NUMBER_NOT_AVAILABLE")}
                                  </div>
                                </div>
                              ) : ewalletErrors.registerPhone ? (
                                <div className="d-flex align-items-center user-available">
                                  <div className="font12 text-lgray3 second text-customred">
                                    {t(ewalletErrors.registerPhone.message)}
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-center">
                          <button 
                            className="t3-custom-light-btn mt-4"
                            style={{ width: "200px" }}
                          >
                            {t("SAVE")}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
      </div>
    )
};

export default AddPhoneModal;