import React, { useEffect, useState, useRef } from "react";
import { themeIcons } from "config/theme";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, Spinner } from "reactstrap";
import Input from "components/custom/Input";
import { ApiKey,ApiUrl } from "util/Constant";
import { createMultiPartFormBody } from "../../util/Util";
import { setResetPassword } from "redux/AppAction";
import VIcon from "components/custom/Icon";
import ApiEngine from "util/ApiEngine";
import { showResponseMessage } from "redux/AppAction";
const ResetPasswordModal = (props) => {
  var { t } = useTranslation();
  var _dispatch = useDispatch();
  const _MIN_PASSWORD_LENGTH = 8;

  const isResetPassword = useSelector(
    (state) => state.appState.isResetPassword
  );
  const [isResetPasswordLoading, setResetPasswordLoading] = useState(false);
  const [hideNewPassword, setHideNewPassword] = useState(true);
  const [hideConfirmPassword, setHideConfirmPassword] = useState(true);

  const {
    register: resetPassword,
    handleSubmit: resetPasswordHandleSubmit,
    errors: resetPasswordErrors,
    setError: setResetPasswordError,
  } = useForm();

  const submitResetPasswordForm = async (data, e) => {

    let { password, confirm_password } = data;

    if (password !== confirm_password) {
      setResetPasswordError("confirm_password", {
        message: "PASSWORD_MISMATCH",
      });
    } else {
      var responseJson = await ApiEngine.post(
        ApiUrl._API_MEMBER_FIRST_TIME_RESET_PASSWORD,
        createMultiPartFormBody(data)
      );

      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        _dispatch(setResetPassword(false));
      }

      _dispatch(
        showResponseMessage(
          responseJson[ApiKey._API_SUCCESS_KEY],
          responseJson[ApiKey._API_MESSAGE_KEY]
        )
      );
    }
  };
  return (
    <div>
      <Modal
        id="t3-custom-modal"
        fade={false}
        contentClassName={"t3-custom-modal"}
        isOpen={isResetPassword}
        toggle={() => {
          _dispatch(setResetPassword(false));
          // setShowModalLogin(false);
        }}
        backdrop={"static"}
        centered
        fullscreen
      >
        <ModalBody>
          <div className="t3-custom-modal-container">
            <div className="modal-header">
              <div className="title">
                <div>{t("CHANGE_PASSWORD")}</div>
              </div>
              <div
                className="close"
                onClick={() => _dispatch(setResetPassword(!isResetPassword))}
              >
                <VIcon icon={themeIcons.close} className="vicon" />
              </div>
            </div>
            <div className="custom-body">
              <form
                onSubmit={resetPasswordHandleSubmit(submitResetPasswordForm)}
              >
                <div className="t3-input-container">
                  <label className="required">{t("NEW_PASSWORD")}</label>
                  <div style={{ position: "relative" }}>
                    <Input
                      type={hideNewPassword ? "password" : "text"}
                      name="password"
                      id="password"
                      className="t3-custom-input-text"
                      placeholder={t("ENTER_NEW_PASSWORD")}
                      autoComplete={"off"}
                      // ref={resetPassword({
                      //   required: "REQUIRED_USERNAME",
                      // })}

                      ref={resetPassword({
                        required:
                          "REGISTER_PAGE_MIN_8_CHARS",
                        pattern: {
                          value: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9]{8,}$/,
                          message: t("PASSWORD_MESSAGE"),
                        },
                        minLength: {
                          value: _MIN_PASSWORD_LENGTH,
                          message: t("MIN_CHARACTERS", {
                            length: _MIN_PASSWORD_LENGTH,
                          }),
                        },
                      })}
                      errors={resetPasswordErrors}
                    />
                    <VIcon
                      icon={
                        hideNewPassword ? themeIcons.eyeBlocked : themeIcons.eye
                      }
                      className="vicon"
                      style={{ top: "20px" }}
                      onClick={() => setHideNewPassword(!hideNewPassword)}
                    />
                  </div>
                </div>
                <div className="t3-input-container mt-3">
                  <label className="required">{t("CONFIRM_PASSWORD")}</label>
                  <div style={{ position: "relative" }}>
                    <Input
                      type={hideConfirmPassword ? "password" : "text"}
                      name="confirm_password"
                      id="confirm_password"
                      className="t3-custom-input-text"
                      placeholder={t("ENTER_CONFIRM_PASSWORD")}
                      autoComplete={"off"}
                      ref={resetPassword({
                        required:
                          "REGISTER_PAGE_MIN_8_CHARS",
                      })}
                      errors={resetPasswordErrors}
                    />
                    <VIcon
                      icon={
                        hideConfirmPassword
                          ? themeIcons.eyeBlocked
                          : themeIcons.eye
                      }
                      className="vicon"
                      style={{ top: "20px" }}
                      onClick={() =>
                        setHideConfirmPassword(!hideConfirmPassword)
                      }
                    />
                  </div>
                </div>
                <div className="custom-button-row ">
                  <button
                    className="t3-custom-light-btn"
                    disabled={isResetPasswordLoading}
                  >
                    {isResetPasswordLoading ? (
                      <Spinner
                        className="dynamic-table-spinner"
                        animation="border"
                        role="status"
                        size="sm"
                        style={{
                          verticalAlign: "middle",
                          width: "1.5rem",
                          height: "1.5rem",
                          marginLeft: 3,
                        }}
                      ></Spinner>
                    ) : (
                      t("SAVE")
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ResetPasswordModal;
