import React, { useEffect, useState } from "react";
import { useSelector} from "react-redux";
import VIcon from "components/custom/Icon";
import { themeIcons } from "config/theme";
import { stringIsNullOrEmpty } from '../../util/Util'
import "../../assets/css/bannerapk.css"
import { useLocation } from 'react-router-dom';
import {
  WebUrl,
} from "../../util/Constant";
import { useTranslation } from "react-i18next";
import { HIDE_APK_BANNER_DURATION } from "../../util/Constant";

const BannerAPKMobile = () => {
  
  const [shouldShowBannerAPK, setShouldShowBannerAPK] = useState(false);
  const [clickCloseBannerAPK, setClickCloseBannerAPK] = useState(false);
  const [bannerAPKUrl,setBannerAPKUrl]=useState("");

  const bannerAPK=useSelector((state)=>state.appState.bannerAPK);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const location = useLocation();
  var { t } = useTranslation();
  var brandName = useSelector((state) => state.appState.brandName);



	useEffect(() => {
    
	init();
    
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };

	}, []);





  async function init() {

    let bannerAPKIcon=bannerAPK;
    
    if(bannerAPKIcon){
    if(!stringIsNullOrEmpty(bannerAPKIcon?.desktopImage) && isValidApkUrl(bannerAPKIcon?.url)){

      setBannerAPKUrl(bannerAPKIcon.url);
      
      if(!clickCloseBannerAPK){
        setShouldShowBannerAPK(true);
      }

    }else{
      setShouldShowBannerAPK(false);
    } 
    }else{
      setShouldShowBannerAPK(false);
    }
  }

  function isValidApkUrl(url) {
    try {

      if(stringIsNullOrEmpty(url))return false;

      const parsedUrl = new URL(url);
      return parsedUrl.href.toLowerCase().endsWith(".apk");
    } catch (e) {
      return false;
    }
  }

  const handleDownloadAPK = () => {
    const fileName = bannerAPKUrl.substring(bannerAPKUrl.lastIndexOf("/") + 1);
    const link = document.createElement("a");
    link.href = bannerAPKUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

 const CloseBtn = (props)=> (
    <button className="close-button" onClick={(e) => {      
        e.stopPropagation();
        setShouldShowBannerAPK(false);
        setClickCloseBannerAPK(true);

        // Expire after 7 days
        localStorage.setItem('hideApkBanner', JSON.stringify({ expiry: Date.now() + HIDE_APK_BANNER_DURATION }));
      }}>
    <VIcon icon={themeIcons.close} className="img" />
    </button>
    
  );

	return (
    <>
      {shouldShowBannerAPK && location.pathname !== "/"+ WebUrl.getCurrentLocale() +"/livechat" && (
        <>
            <div className="mobile-apk-banner">
              <div className="banner-left">
                <div>
                  <img
                    src={bannerAPK["apkIcon"]}
                    className="img"
                    alt={bannerAPK["apkIcon"]}
                  />
                </div>
                <div>
                <div className="txt-top">{t("APK_UP_LBL", { brandName })}</div>
                <div className="txt-btm">{t("APK_BTM_LBL")}</div>
              </div>
            </div>
        
            <div
                className="download-button"
                onClick={handleDownloadAPK}
            >
                <div>
                  <VIcon
                    icon={themeIcons.download}
                    className="img"
                  />
                </div>
                <div className="text">{t("DOWNLOAD")}</div>
                </div>
            <CloseBtn />
            </div>
          </>
      )}
    </>
	);
};

export default BannerAPKMobile;
